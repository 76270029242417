import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";
import Features from "../components/Features";
import TestimonialsUnveil from "../components/TestimonialsUnveil";

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  whatWeDo,
  process,
  clientTestimonials,
  clients
}) => {
  const PageContent = contentComponent || Content;

  return (
    <Fragment>
      <PageHeader title={title} paintColor={"pink"} />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="">
              <section className="section">
                <div className="columns">
                  <div className="column">
                    <div className="content">
                      <h3 className="is-size-1 title-underline">
                        {whatWeDo.heading}
                      </h3>
                      <p className="is-size-5">{whatWeDo.description}</p>
                      <Link className="btn btn-outline" to={whatWeDo.linkTo}>
                        {whatWeDo.linkText}
                      </Link>
                    </div>
                  </div>
                  {/* <div className="column">Video placeholder</div> */}
                </div>
              </section>
              <section>
                <div className="columns">
                  <div className="column is-12">
                    <Features gridItems={whatWeDo.icons} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div id="process">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="">
                <section className="section">
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <h3 className="is-size-1 title-underline">
                        {process.heading}
                      </h3>
                      <div>
                        <img src={process.image.childImageSharp.fluid.src} />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TestimonialsUnveil style={"about"} />

      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="">
              <section className="section">
                <div className="columns">
                  <div className="column">
                    <div className="content has-text-centered">
                      <h3 className="is-size-1 title-underline">
                        {clients.heading}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="columns is-multiline is-centered">
                  {clients.logos.map(client => (
                    <div className="column is-4 has-text-centered">
                      <img src={client.image.childImageSharp.fluid.src} />
                    </div>
                  ))}
                </div>
                <div className="columns">
                  <div className="column has-text-centered">
                    <Link className="btn btn-outline" to={clients.linkTo}>
                      {clients.linkText}
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        whatWeDo={post.frontmatter.whatWeDo}
        process={post.frontmatter.process}
        clientTestimonials={post.frontmatter.clientTestimonials}
        clients={post.frontmatter.clients}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        whatWeDo {
          heading
          description
          linkText
          linkTo
          videoLink
          icons {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
        process {
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkText
          linkTo
        }
        clientTestimonials {
          heading
          quotes {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            name
          }
        }
        clients {
          heading
          logos {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          linkText
          linkTo
        }
      }
    }
  }
`;
